require('dotenv').config();

export default class Okta {
  static url = process.env.REACT_APP_OKTA_OAUTH_URL;
  static callbackURL = process.env.REACT_APP_OKTA_OAUTH_CALLBACK_URL;
  static clientId = process.env.REACT_APP_OKTA_OAUTH_CLIENT_ID;
  
  static getURL() {
    return `https://${Okta.url}.oktapreview.com/oauth2/default`;
  }
  static getCallbackURL(){
    return window.location.origin + Okta.callbackURL;
  }
  static getClientId(){
    return Okta.clientId;
  }
}
