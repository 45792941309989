import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { CopyRight } from './components';
import { NavLink } from 'react-router-dom';

import './Footer.scss';

export default withAuth(class Footer extends Component {
  state = {
    authenticated: null
  };
  
  checkAuthentication = async() => {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }
  
  async componentDidMount() {
    this.checkAuthentication();
  }
  
  async componentDidUpdate() {
    this.checkAuthentication();
  }

  render() {
    let links;
    if(this.state.authenticated === true) {
      links = (
        <React.Fragment>
          <NavLink className="nav-link" exact to="/">
            Home
          </NavLink>
          <NavLink className="nav-link" exact to="/Photos">
            Photos
          </NavLink>
          <NavLink className="nav-link" to="/Photos/Create">
            Create Photo
          </NavLink>
        </React.Fragment>
     
      )
    }
    else {
      links = (
        <React.Fragment>
          <NavLink className="nav-link" exact to="/">
            Home
          </NavLink>
          <NavLink className="nav-link" exact to="#">
            Login
          </NavLink>
        </React.Fragment>
      )
    }
    
    return (
      <div className="Footer row d-flex justify-content-center pt-4">
      
        <div className="col-12 d-flex justify-content-center">
          {links}
        </div>
      
        <div className="col-12 text-center my-2">
          <CopyRight/>
        </div>
      </div>
    );
  }
});
