import React, { Component } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Security, SecureRoute, ImplicitCallback } from '@okta/okta-react';
import Loadable from 'react-loadable';
import { Container } from 'reactstrap';
import { Navigation, Footer } from './containers';
import { Loading } from './UI/components/';
import Okta from './utilities/Okta';

const config = {
  issuer: Okta.getURL(),
  redirect_uri: Okta.getCallbackURL(),
  client_id: Okta.getClientId()
}

const asyncHome = Loadable({
  loader: () => import('./ReactRoutes/containers/Home/Home'),
  loading: Loading,
  delay: 300 // don't show loading component unless longer than .3 seconds
});
const asyncPhotos = Loadable({
  loader: () => import('./ReactRoutes/containers/Photos/Photos'),
  loading: Loading,
  deploy: 300
});
const asyncCreatePhoto = Loadable({
  loader: () => import('./ReactRoutes/containers/CreatePhoto/CreatePhoto'),
  loading: Loading,
  deploy: 300
});
const asyncPhoto = Loadable({
  loader: () => import('./ReactRoutes/containers/Photo/Photo'),
  loading: Loading,
  deploy: 300
});

export default class App extends Component {
  
  render() {
    return (
      <div className="App">
        <Router>
          <Security
            issuer={config.issuer}
            client_id={config.client_id}
            redirect_uri={config.redirect_uri}
          >
          <Container fluid className="Main">
            <Navigation />
            <div className="Content">
              <Route exact path="/" component={asyncHome} />
              <Route path='/implicit/callback' component={ImplicitCallback}/>
  
              <SecureRoute exact path="/Photos/" component={asyncPhotos} />
              <SecureRoute exact path="/Photos/collections/:collection" component={asyncPhotos} />
              <SecureRoute exact path="/Photos/Create" component={asyncCreatePhoto} />
              <SecureRoute path="/Photo/:id" component={asyncPhoto} />
              
            </div>
            <Footer />
          </Container>
          </Security>
        </Router>
      </div>
    );
  }
};
