import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import SideNav, { MenuIcon } from 'bms-react-simple-sidenav';
import { BrandLogo, NavItems } from './components';
import { Navbar, Nav } from 'reactstrap';
import './Navigation.scss';

export default withAuth(class Navigation extends Component {
  state = {
    showNav: false,
    authenticated: null
  };
  checkAuthentication = async() => {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }
  
  async componentDidMount() {
    this.checkAuthentication();
  }
  
  async componentDidUpdate() {
    this.checkAuthentication();
  }
  
  login = async() => {
    this.props.auth.login('/Photos');
  }
  
  closeNavigation = () => {
    this.setState({ showNav: false });
  };

  render() {
    const navItemProps = { authenticated: this.state.authenticated, login: this.login };
    
    return (
      <div className="Navigation row d-flex" data-scroll-header="">
        <Navbar expand="md" className="w-100">
          <Nav className="flex-grow-1 justify-content-center brandLogo">
            <li>
              <BrandLogo />
            </li>
          </Nav>
          <Nav navbar>
            <li className="d-none d-md-inline-flex">
              <NavItems {...navItemProps}/>
            </li>
            <li className="d-inline-flex d-md-none MenuIcon">
              <MenuIcon onClick={() => this.setState({ showNav: true })} />
            </li>
          </Nav>
        </Navbar>

        <div className="SideNav">
          <SideNav
            className="SideNav"
            openFromRight={true}
            showNav={this.state.showNav}
            onHideNav={() => this.setState({ showNav: false })}
            title={<BrandLogo />}
            items={[<NavItems {...navItemProps} closeNavigation={this.closeNavigation} />]}
          />
        </div>
      </div>
    );
  }
});
