const delimiter = '-';

export const buildFilterObject = (pictures) => {
  const years = getUniqueFilters(pictures);
  const tags = getUniqueFilters(pictures, 'tags');
  
  return {
    ...buildTagObject(tags),
    year: years
  }
}

export const getUniqueFilters = (pictures, key = 'year') => {
  let array = [];
  pictures.forEach((picture) => {
    array = [].concat(array, picture[key]);
  });
  return Array.from(new Set(array));
}

export const buildTagObject = (tags) => {
  let obj = {};
  tags.forEach((tag) => {
    const parts = tag.split(delimiter);
    if(!parts[1]){
      return;
    }
    if(obj[parts[0]] !== undefined ) { // The Array already exists...
      obj[parts[0]] = [].concat(obj[parts[0]], parts[1]);
    }
    else{
      obj[parts[0]] = new Array(parts[1]);
    }
  });
  return obj;
}

export const filterPictures = (pictures, queryArray) => {
  const queryObj = convertFilterArray(queryArray);
  return pictures.filter((picture) => {
    return isValidQueryPicture(picture, queryObj);
  });
}

/**
 * 
 * @param queryArray - An Array of objects with 1 key, key is the Category, value is the Filter
 * @return - Returns an object with the year and tags being converted back to their original form as arrays.
 */
export const convertFilterArray = (queryArray) => {
  let years = [];
  let tags = [];
  
  queryArray.forEach((obj) => {
    for(const [key, value] of Object.entries(obj)){
      if(key === 'year'){
        years.push(value);
      }
      else {
        const tag = key + delimiter + value;
        tags.push(tag);
      }
    }
  });
  return {
    years: years,
    tags: tags
  }
}

export const isValidQueryPicture = (picture, queryObj) => {
  let validPicture = true;
  for(const [key, values] of Object.entries(queryObj)){
    values.forEach((value) => {
      if(key === 'years' ){
        if(picture.year !== value) {
          validPicture = false;
        }
      }
      else {
        if(!picture.tags.includes(value)) {
          validPicture = false;
        }
      }
    });
  }
  
  return validPicture;
}
