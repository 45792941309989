import React from 'react';

export const BrandLogo = () => {
  return (
    <div className="BrandLogo d-flex justify-content-center container-fluid">

      
    </div>
  );
};
