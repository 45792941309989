import React from 'react';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './NavItems.scss';

export const NavItems = ({ closeNavigation, authenticated, login, logout }) => {
  let links;
  if(authenticated === true) {
    links = (
      <ul className="NavItems d-md-inline-flex">
        <NavLink onClick={closeNavigation} className="nav-link" exact to="/">
          <NavItem>Home</NavItem>
        </NavLink>
        <NavLink onClick={closeNavigation} className="nav-link" exact to="/Photos">
          <NavItem>Photos</NavItem>
        </NavLink>
        <NavLink onClick={closeNavigation} className="nav-link" to="/Photos/Create">
          <NavItem>Create Photo</NavItem>
        </NavLink>
      </ul>
    )
  }
  else {
    links = (
      <ul className="NavItems d-md-inline-flex">
        <NavLink onClick={closeNavigation} className="nav-link" exact to="/">
          <NavItem>Home</NavItem>
        </NavLink>
        <NavLink onClick={login} className="nav-link" exact to="#">
          <NavItem>Login</NavItem>
        </NavLink>
      </ul>
    )
  }
  
  return links
};
